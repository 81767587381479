import React, { Key } from "react";
import { Tabs, Tab, Badge } from "@nextui-org/react";
import { TPlan, TPeriod } from "@/types";
import { PlanCard } from "./PlanCard";

const PLANS: TPlan[] = [
  {
    id: 0,
    name: "Starter",
    description:
      "For individual truck dispatchers: Work faster and book more loads.",
    dispatchersCount: 1,
    price: 18,
    features: [
      "Unlimited Emails",
      "1 Email Template",
      "Click to Сall",
      "RPM Calculator",
      "Load Filters",
      "Google Maps Integration",
      "DAT, Sylectus, Truckstop, LoadLink",
    ],
    isMultipleDispatchers: false,
    paymentLink:
      "https://api.carrierify.com/checkout?price=price_1OUVlzH9n8OPJhQCK2ZQwgsK",
    paymentLinkYearly:
      "https://api.carrierify.com/checkout?price=price_1OWyPyH9n8OPJhQCwxEA6o3v",
  },
  {
    id: 1,
    name: "New Pro",
    description:
      "For experienced dispatchers: Make quick, smart decisions and book loads faster.",
    dispatchersCount: 1,
    price: 28,
    previousPlan: "Starter",
    features: [
      "Multiple Email Templates",
      "Email Signature",
      "Factoring Score",
      "FMCSA Broker Lookup",
      "Tolls & Diesel Calculator",
      "Dedicated Support",
    ],
    isMultipleDispatchers: false,
    paymentLink:
      "https://api.carrierify.com/checkout?price=price_1QSGvfH9n8OPJhQCBFkghonb",
    paymentLinkYearly:
      "https://api.carrierify.com/checkout?price=price_1QSGybH9n8OPJhQCuech1PJ6",
  },
  {
    id: 2,
    name: "Business",
    description:
      "For dispatch teams: Access advanced analytics, load history, and simplified management.",
    dispatchersCount: 2,
    price: 26,
    previousPlan: "Pro",
    features: ["Multiple Email Accounts"],
    futureFeatures: [
      "Team Management",
      "Dispatcher Analytics",
      "Productivity Monitoring",
      "Load Email & Call History",
    ],
    isMultipleDispatchers: true,
    paymentLink: `https://api.carrierify.com/checkout?prod=prod_OW3FJJl4vElyvj&interval=month`,
    paymentLinkYearly: `https://api.carrierify.com/checkout?prod=prod_OW3FJJl4vElyvj&interval=year`,
    getPriceByDispatchers: (dispatchersCount: number) => {
      if (dispatchersCount <= 1) return 28;
      if (dispatchersCount <= 10) return 24;
      if (dispatchersCount <= 20) return 22;
      if (dispatchersCount <= 30) return 20;
      if (dispatchersCount <= 40) return 18;

      return 28;
    },
  },
];

export const PlansNew = () => {
  const [period, setPeriod] = React.useState<TPeriod>("monthly");

  const handlePeriodChange = (period: Key) => {
    setPeriod(period as TPeriod);
  };

  return (
    <div className="flex flex-col gap-6 items-center rotat">
      <h2 className="mb-4">Choose the plan that works for you!</h2>
      <Badge
        classNames={{
          badge:
            "px-4 py-[2px] bg-[#A2F28E] text-[#008E1E] rotate-[30deg] text-xs",
        }}
        content="-10%"
      >
        <Tabs
          aria-label="Period"
          variant="bordered"
          color="primary"
          radius="full"
          size="lg"
          selectedKey={period}
          onSelectionChange={handlePeriodChange}
        >
          <Tab key="monthly" title="Monthly" />
          <Tab key="yearly" title="Yearly" />
        </Tabs>
      </Badge>
      <div className="flex flex-wrap gap-4 justify-center">
        {PLANS.map((plan) => (
          <PlanCard key={plan.id} plan={plan} period={period} />
        ))}
      </div>
    </div>
  );
};
